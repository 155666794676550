import { useState } from 'react';
import './App.css';
import Two from './pages/Two';
import Three from './pages/Three';

function App() {

  const [Aplicaciones, setAplicaciones] = useState([
    {
      id:"0",
      title: "Portafolio",
      img: "",
      logo: "/favicon.png",
      link: "https://portafolio.apidev.info/",
      description: "Aplicaion web tipo galeria para listar las aplicaciones creadas para empresas Ariztia",
      repo:"https://github.com/marcuxo/portafolio_apps/",
      release:"Aun en desarrollo.",
      last: ""
    },
    {
      id:"1",
      title: "ctrl medidores",
      img: require('./assets/ctrlmedidores_img.PNG'),//"/captures/ctrlmedidores_img.png",
      logo: "/favicon.png",
      link: "https://drive.google.com/drive/folders/11VfbKJYgGNf4zAOAIv_ZTSEmcFzeaCxq?usp=drive_link",
      description: "Aplicación Android para la captura los consumos de los medidores que hay en la planta, además se pueden agregar los medidores de empalmes eléctricos para llevar los consumos eléctricos como registro, esta aplicación funciona en conjunto con una aplicación web la cual muestra los datos capturados además de poder modificarlos en caso de algún error.",
      repo:"https://github.com/marcuxo/medicontrol_v8",
      release:"Ultima version lanzada 25/05/2023.",
      last: ""
    },
    {
      id:"2",
      title: "PROBOCA",
      img: require('./assets/proboca_img.PNG'),//"/captures/proboca_img.png",
      logo: "/favicon.png",
      link:"https://drive.google.com/drive/folders/1uQZbH8SZKThIPvaVwbdmWnod0oiTh3-w?usp=drive_link",
      description: "Aplicación Android para la captura de los datos de trabajo de las maquinas armadoras de cajas, tanto, así como las cajas armadas por turno como la velocidad de armado de cajas por hora. Igualmente se agregó el control de las detenciones de la maquina con una detallada descripción de la detención para tener un correcto informe de detenciones para el historial de la maquina",
      repo:"https://github.com/marcuxo/PRO.BO.CA_app_v1",
      release:"En pruebas desde el 23/05/2023.",
      last: ""
    },
    {
      id:"3",
      title: "Ariztock",
      img: require('./assets/ariztock_img.PNG'),//"/captures/ariztock_img.png",
      logo: "/favicon.png",
      link:"https://ariztock.apidev.info",
      description: "",
      repo:"",
      release:"",
      last: ""
    },
    {
      id:"4",
      title: "Inventario",
      img: require('./assets/inventario_img.PNG'),//"/captures/inventario_img.png",
      logo: "/favicon.png",
      link:"https://inventario.pages.dev",
      description: "Aplicación web para gestionar la toma de inventario, da una ayuda al momento de generar un conteo para el inventario, mediante el código es capaz de mostrar la imagen asociada al código para la verificación de esta al momento de contarlo, permite identificar a los responsables que realizaron el conteo de cada ítem además de generar un reporte con el listado de los ítems contados y su valor para el cálculo del inventario.",
      repo:"https://github.com/marcuxo/react-inventario",
      release:"Se lanzó a produccion 03/02/2023.",
      last: ""
    },
    {
      id:"6",
      title: "C19 Ariztia",
      img: require('./assets/c19_img.PNG'),//"/captures/c19_img.png",
      logo: "/favicon.png",
      link:"https://c19ariztia.pages.dev",
      description: "",
      repo:"",
      release:"",
      last: ""
    },
    {
      id:"7",
      title: "Etiquetas",
      img: require('./assets/etiqueta_img.PNG'),//"/captures/etiqueta_img.png",
      logo: "/favicon.png",
      link:"https://eticketas.apidev.info",
      description: "",
      repo:"",
      release:"",
      last: ""
    },
    // {
    //   id:"8",
    //   title: "Accesos",
    //   img:"",
    //   logo: "/favicon.png",
    //   link:"https://accesos.pages.dev",
    //   description: "",
    //   repo:"",
    // release:"",
    // last: ""
    // },
    {
      id:"9",
      title: "Horas Fracttal",
      img: require('./assets/horasfracttal_img.PNG'),//"/captures/horasfracttal_img.png",
      logo: "/favicon.png",
      link:"https://horasfracttal.apidev.info",
      description: "",
      repo:"",
      release:"",
      last: ""
    },
    {
      id:"10",
      title: "Botas",
      img: require('./assets/botas_img.PNG'),//"/captures/botas_img.png",
      logo: "/favicon.png",
      link:"https://reis.apidev.info",
      description: "",
      repo:"",
      release:"",
      last: ""
    },
    {
      id:"11",
      title: "Fugatorio",
      img: require('./assets/fugatorio_img.PNG'),//"/captures/fugatorio_img.png",
      logo: "/favicon.png",
      link:"https://fugatorio.apidev.info",
      description: "Aplicación web que permite tener un historial de las fugas y derrames de amoniaco en la planta, esta aplicación permite que cada vez que se ingrese una nueva fuga o derrame de amoniaco enviara una alerta mediante mensaje de texto, programado en un Bot de telegran al cual se sebe suscribir para comenzar a recibir las alertas, además la aplicación permite descargar un reporte en Excel con los datos recopilados para su uso.",
      repo: "https://github.com/marcuxo/fugatorio",
      release:"Se lanzó a produccion el 23/08/2022.",
      last: ""
    },
    {
      id: "12",
      title: "Control Inyeccion",
      img: require('./assets/inyeccion_img.PNG'),//"/captures/inyeccion_img.png",
      logo: "/favicon.png",
      link:"https://control-inyeccion.apidev.info",
      description: "Aplicación web para registrar los controles de inyección realizados en las inyectoras de la planta, esta aplicación permite llevar el control con la intermitencia necesaria, la cual genera el porcentaje de inyección calculado al momento, esta aplicación permite él envió de alertas mediante mensaje, programado un Bot en telegram, el cual genera alertas cada vez que el porcentaje de inyección esta fuera de los rangos preestablecidos para cada producto.",
      repo: "https://github.com/marcuxo/inyeccion-app",
      release:"Se lanzó a produccion el 28/10/2022.",
      last: ""
    },
    {
      id: "13",
      title: "Medidores",
      img: require('./assets/medidores_img.PNG'),//"https://apimedidores.apidev.info/captures/medidores_img.png",
      logo: "/favicon.png",
      link:"https://medidores.apidev.info",
      description: "Aplicación web que trabaja en paralelo con la aplicación Android para la captura de las lecturas de los flujómetros en planta, esta aplicación permite ver los datos guardados por la aplicación Android, graficarlos además de modificarlos en caso de tener un error en las lecturas de los flujómetros, esta aplicación permite generar un reporte en Excel el cual contiene todo el historial de los datos capturados por la aplicación.",
      repo:"https://github.com/marcuxo/dash-indicadores",
      release:"Se lanzó a produccion el 28/10/2021.",
      last: ""
    },
    {
      id: "14",
      title: "Consumos Hidricos",
      img: require('./assets/consumos_img.PNG'),//"https://apimedidores.apidev.info/captures/consumos_img.png",
      logo: "/favicon.png",
      link:"https://consumos-hidricos.apidev.info",
      description: "Aplicación web que permite recopilar los datos de consumos de agua calculados con la cantidad de kilogramos de ave procesados por día, además permite llevar otros datos calculados de consumos hídricos y sacar cálculos con los valores de la uf actualizado al día o el Dólar actualizado (los valores del Dólar y UF son recopilados el mismo día desde el banco central), calculando los costos de tratamientos de aguas.",
      repo: "https://github.com/marcuxo/consumos_hidricos",
      release:"Se lanzó a produccion el 21/12/2022.",
      last: ""
    },
    {
      id: "15",
      title: "Transportes",
      img: require('./assets/transporte_img.PNG'),//"captures/transporte_img.png",
      logo: "/favicon.png",
      link:"https://transporte.apidev.info",
      description: "Aplicación para coordinar la solicitud de transporte a los colaboradores de la empresa, la coordinación de las solicitudes se gestiona mediante un Excel maqueta que se puede descargar de la aplicación donde deben solicitar los transportes para la semana siguiente, al generar estas solicitudes se generan correos con información los cuales llegan a la empresa de transporte para coordinar la salida de los furgones para recoger a los usuarios.",
      repo: "https://github.com/marcuxo/control-transporte-01",
      release:"Se lanzó a produccion el 28/03/2023.",
      last: ""
    },
    {
      id: "16",
      title: "SOTC",
      img: require('./assets/sotc_img.PNG'),//"captures/sotc_img.png",
      logo: "/favicon.png",
      link:"https://sotc.apidev.info/",
      description: "Formulario web para crear una orden de trabajo correctivo, Pendiente: inmediato, programado o planificado. Finalizado: agregar recursos y horarios de inicio y fin.",
      repo: "https://github.com/marcuxo/S_O_T_C",
      release:"Acualmente en desarrollo.",
      last: ""
    },
    {
      id: "17",
      title: "SPC",
      img:require('./assets/spc_img.PNG'),
      logo: "/favicon.png",
      link:"https://spc.apidev.info",
      description: "",
      repo:"",
      release:"",
      last: ""
    },
  ]);

  return (
    <>
       <div className="App" id='main'>
        <Two aplicaciones={Aplicaciones} />
       </div>
       {
        Aplicaciones.map(apk=><Three
          key={apk.id}
          Props={apk}
          />)
      }
    </>
  );
}

export default App;
