import React from 'react'
import { FcFolder } from "react-icons/fc";
import { AiOutlineLink } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";

function Three({Props}) {
  return (
    <>
      <div className="paginaMain"  id={Props.id}>
        <div className="texto-pag">
          <div className="img-card">
            <img src={Props.img} className="img-pag"/>
          </div>
          <div className="cnt-pag">
            <p><a href='#main'><FcFolder /> {Props.title}</a></p>
            <p className='font-text'>{Props.description}</p>
            <p className='font-text'><br/></p>
            <p className='font-release'>{Props.release}</p>
            <p className='font-text'><a href={Props.link}><AiOutlineLink style={{fontSize:'30px'}}/></a><span style={{marginLeft:"15px"}}></span><a href={Props.repo}><AiFillGithub style={{fontSize:'30px'}} /></a></p>
            <p className='font-text'></p>
            <a href='#main' style={{color:'white'}}><AiOutlineUp style={{color: 'white'}} />menu</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Three