import React, { useEffect, useState } from 'react'
import { FcFolder } from "react-icons/fc";

function Two({aplicaciones}) {
  // const [Aplicaciones, setAplicaciones] = useState([
  //   {
  //     id:1,
  //     title: "ctrl medidores",
  //     img: "/favicon.png",
  //     link: "https://drive.google.com/drive/folders/11VfbKJYgGNf4zAOAIv_ZTSEmcFzeaCxq?usp=drive_link",
  //     description: "Aplicacion android para anotar los consumos de los flujometros seleccionados en planta",
  //     repo:"https://github.com/marcuxo/medicontrol_v8"
  //   },
  //   {
  //     id:2,
  //     title: "PROBOCA",
  //     img: "/favicon.png",
  //     link:"https://drive.google.com/drive/folders/1uQZbH8SZKThIPvaVwbdmWnod0oiTh3-w?usp=drive_link",
  //     description: "Aplicaion Android que lleva el control de status de las maquinas armadoras de cajas e igualmente las detenciones que estas tengan",
  //     repo:"https://github.com/marcuxo/PRO.BO.CA_app_v1"},
  //   {
  //     id:3,
  //     title: "Ariztock",
  //     img: "/favicon.png",
  //     link:"https://ariztock.pages.dev",
  //     description: "",
  //     repo:""
  //   },
  //   {
  //     id:4,
  //     title: "Inventario",
  //     img: "/favicon.png",
  //     link:"https://inventario.pages.dev",
  //     description: "Aplicacion web para ayudar en la gestion de la toma de inventario en bodega pañol",
  //     repo:""
  //   },
  //   {
  //     id:6,
  //     title: "C19 Ariztia",
  //     img: "/favicon.png",
  //     link:"https://c19ariztia.pages.dev",
  //     description: "",
  //     repo:""
  //   },
  //   {
  //     id:7,
  //     title: "Etiquetas",
  //     img: "/favicon.png",
  //     link:"https://eticketas.apidev.info",
  //     description: "",
  //     repo:""
  //   },
  //   {
  //     id:8,
  //     title: "Accesos",
  //     img: "/favicon.png",
  //     link:"https://accesos.pages.dev",
  //     description: "",
  //     repo:""
  //   },
  //   {
  //     id:9,
  //     title: "Horas Fracttal",
  //     img: "/favicon.png",
  //     link:"https://horasfracttal.apidev.info",
  //     description: "",
  //     repo:""
  //   },
  //   {
  //     id:10,
  //     title: "Botas",
  //     img: "/favicon.png",
  //     link:"https://reis.apidev.info",
  //     description: "",
  //     repo:""
  //   },
  //   {
  //     id:11,
  //     title: "Fugatorio",
  //     img: "/favicon.png",
  //     link:"https://fugatorio.apidev.info",
  //     description: "Aplicacion web que permite llebar un control sobre las fugas de amoniaco ocurridas en planta",
  //     repo: "https://github.com/marcuxo/fugatorio"
  //   },
  //   {
  //     id: 12,
  //     title: "Control Inyeccion",
  //     img: "/favicon.png",
  //     link:"https://control-inyeccion.apidev.info",
  //     description: "Aplicacion Web para llevar control de los porcentajes de inyeccion en planta",
  //     repo: "https://github.com/marcuxo/inyeccion-app"
  //   },
  //   {
  //     id: 13,
  //     title: "Indicadores",
  //     img: "/favicon.png",
  //     link:"https://medidores.apidev.info",
  //     description: "Aplicaion Web que grafica y muestra los consumos de flujometros tomado en planta, trabaja en conjunto con la Aplicacion Android 'ctrl medidores'",
  //     repo:"https://github.com/marcuxo/dash-indicadores"
  //   },
  //   {
  //     id: 14,
  //     title: "Consumos Hidricos",
  //     img: "/favicon.png",
  //     link:"https://consumos-hidricos.apidev.info",
  //     description: "Aplicaion Web con los consumos de agua por cantidad de kg de ave y otros consumos y medidores de agua de la planta",
  //     repo: "https://github.com/marcuxo/consumos_hidricos"
  //   },
  //   {
  //     id: 15,
  //     title: "Transportes",
  //     img: "/favicon.png",
  //     link:"https://transporte.apidev.info",
  //     description: "Aplicacion Web para gestionar la solicitud de transportes a la empresa Go-Transfer",
  //     repo: "https://github.com/marcuxo/control-transporte-01"
  //   },
  //   {
  //     id: 16,
  //     title: "SOTC",
  //     img: "/favicon.png",
  //     link:"https://sotc.apidev.info/",
  //     description: "Aplicaion Web tipo formulario para la Solicitud De Ordenes de Trabajo Correctivos",
  //     repo: "https://github.com/marcuxo/S_O_T_C"
  //   },
  //   {
  //     id: 17,
  //     title: "SPC",
  //     img: "/favicon.png",
  //     link:"https://spc.apidev.info",
  //     description: "",
  //     repo:""
  //   },
  // ]);

  const [Aplicaciones, setAplicaciones] = useState({})

  useEffect(() => {
    const elementos = window.document.querySelectorAll('#card_logo')
    const el = window.document.getElementById('card_logo')
    // console.log(elementos, el?.clientWidth, el?.clientHeight)s
    const height = el?.clientHeight
    const width = el?.clientWidth
    elementos.forEach(el_=>{
      el_.addEventListener('mousemove', (MouseEvent)=>{
        let {layerX, layerY} = MouseEvent
        const yRotation = (
          (layerY - height / 2) / height
        ) * 20

        const xRotation = (
          (layerX - width / 2) / width
        ) * 20

        const string = `
          perspective(500px)
          scale(1.1)
          rotateY(${xRotation}deg)
          rotateX(${yRotation}deg)
        `
        el_.style.transform = string
      })
      el_.addEventListener('mouseout', () => {
        el_.style.transform = `
          perspective(500px)
          scale(1)
          rotateX(0)
          rotateY(0)
        `
        // el_.style = `z-index: 0;`
      })
    })

  }, [])

 
  return (
    <div className="paginaMain_logos" >
      {
        aplicaciones.map(apk => 
              <a href={`#${apk.id}` }id={`card_logo`} key={apk.id}>
            <div  >
              <img className='' src={apk.logo} alt="" width={'80px'} />
              <br />
              <span>{apk.title}</span>
            </div>
              </a>
        )
      }
      
      
    </div>
  )
}

export default Two